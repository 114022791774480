/* 1280x800 */
@media (max-width: 800px) {
  .search-toggle-button {
    margin-left: 12px;
  }

  .search-panel .show-mobile {
    display: block;
  }

  .search-panel .hide-mobile {
    display: none;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .search-toggle-button {
    margin-left: 12px;
  }

  .search-panel .show-mobile {
    display: block;
  }

  .search-panel .hide-mobile {
    display: none;
  }
}

@media (min-width: 1281px) {
  .search-toggle-button {
    margin-left: 24px;
  }

  .search-panel .show-mobile {
    display: none;
  }

  .search-panel .hide-mobile {
    display: inline-block;
  }
}

.search-toggle-button {
}

.search-panel {
  background-color: #e7e7e7;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 24px;
  padding: 8px 24px;
  border-top: solid #cfbcbc .025em;
  border-bottom: solid #cfbcbc .025em;
}

.search-panel {
  /*transition: visibility 300ms ease;*/
}

.search-panel-show {
  display: block;
  /*visibility: visible;*/
}

.search-panel-hide {
  display: none;
  /*visibility: hidden;*/
}

.search-panel .search-fields {
  margin-top: 8px;
  margin-bottom: 4px;
}

.search-panel .search-fields .form-control {
  display: inline-block;
}

.search-panel .search-fields .sm-field {
  width: 140px;
}

.search-panel .search-fields .md-field {
  width: 200px;
}

.search-panel .search-fields .lg-field {
  width: 260px;
}

.search-panel .search-fields .has-autocompleted-value {
  background-color: #d9eaff;
}

.search-panel .search-fields .date-field {
  display: inline-block;
  /*width: 160px;*/
  margin-right: 8px;
}

.search-panel .search-fields .from-date-field {
  margin-right: 0;
}

.search-panel .search-fields .padded-left {
  margin-left: 8px;
}

.search-panel .search-fields input,
.search-panel .search-fields select {
  margin-right: 8px;
}

.search-panel .search-fields input[type=checkbox],
.search-panel .search-fields input[type=radio] {
  cursor: pointer;
}

.search-panel .search-fields label.with-border {
  margin-right: 12px;
  background-color: white;
  border: solid 1px lightgray;
  padding: 6px 8px;
  border-radius: 4px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.search-panel .search-fields .warning-message {
  padding: 6px 12px 6px 0;
  color: #d18c0e;
}

.search-panel .search-fields .warning-message > .fa-solid {
  margin-right: 8px;
}


.search-panel .search-controls {
  margin-top: 12px;
  margin-bottom: 8px;
}

.search-panel .search-controls .padded-left {
  margin-left: 8px;
}

.search-panel .search-controls button {
  margin-right: 12px;
}
