.purchase-agreements-container {
}

.purchase-agreement-entry-form {
  margin-top: 20px;
}

.purchase-agreement-controls {
  margin-top: 16px;
  margin-bottom: 30px;
}

.purchase-agreement-controls button {
  margin-right: 16px;
}

.cancel-warning {
  font-style: italic;
  color: #a6810d;
}

.cancel-warning > i {
  margin-right: 8px;
  color: #544108;
}
