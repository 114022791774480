.content-area {
  background-color: white;
  box-shadow: 0 0 8px #b6b3b3;
  padding: 24px;
  margin-top: 32px;
}

/* 1280x800 */
@media (max-width: 800px) {
  .content-area-main {
    grid-column: 1/13;
  }

  .content-area-side {
    grid-column: 1/13;
    margin-top: 0;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .content-area-main {
    grid-column: 1/8;
  }

  .content-area-side {
    grid-column: 8/13;
  }
}

@media (min-width: 1281px) {
  /* --Used alongside .content-container-grid defined in App.css-- */
  .content-area-main {
    grid-column: 1/9;
  }

  .content-area-side {
    grid-column: 9/13;
  }
}

.content-area-side .info-panel {
  border: solid 2px #A7A7A7;
  border-radius: 4px;
  padding: 16px 24px;
  margin-bottom: 16px;
}

.content-area-side .info-panel h2 {
  font-size: 22px;
  font-weight: bold;
  display: block;
  height: 32px;
}

.content-area-side .info-panel .panel-contents {
  margin-top: 12px;
}

.content-area-side .info-panel .panel-contents ul {
  padding-left: 18px;
}

.content-area-side .info-panel .panel-controls {
  margin-top: 12px;
}
/* ---- */

.background-area {
  padding: 24px;
  margin-top: 32px;
}
