.autocomplete-result-container {
}

.autocomplete-results {
  border-radius: 6px;
  border: 1px solid lightgray;
  margin-top: 6px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
}

.autocomplete-group-header {
  padding: 6px 12px;
  background-color: white;
  font-weight: bold;
  font-size: 14px;
}

.autocomplete-result-item {
  padding: 6px 12px;
  cursor: pointer;
  background-color: white;
}

.autocomplete-result-item:hover {
  background-color: #EEEEEE;
}

.autocomplete-result-item:active {
  background-color: #a6a6a6;
}

.result-item-header {
  font-weight: bold;
}

.result-item-offset {
  padding-left: 32px;
}
