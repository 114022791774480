.inquiry-table {
}

.inquiry-table table .fullname-col {
  min-width: 130px;
}

.inquiry-table table .contact-no-col {
  min-width: 100px;
}

.inquiry-table table .city-township-col {
  min-width: 100px;
}

.inquiry-table table .source-col {
  min-width: 70px;
}

.inquiry-table table .business-type-col {
  min-width: 100px;
}

.inquiry-table table .date-col {
  min-width: 130px;
}

.inquiry-table table .genset-model-col {
  min-width: 140px;
}

.inquiry-table table .followup-col {
  min-width: 140px;
}

.inquiry-table table .reporter-col {
  min-width: 120px;
}

.sales-inquiries-container {
}

