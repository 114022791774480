/* 1280x800 */
@media (max-width: 800px) {
  .data-table {
    overflow-x: auto;
  }

  .data-table thead .hide-mobile,
  .data-table tbody .hide-mobile {
    display: none;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .data-table {
    overflow-x: auto;
  }

  .data-table thead .hide-mobile,
  .data-table tbody .hide-mobile {
    display: none;
  }
}

@media (min-width: 1281px) {
  .data-table {
    overflow-x: unset;
  }

  .data-table thead .hide-mobile,
  .data-table tbody .hide-mobile {
    display: block;
  }
}

.data-table {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 30px;
}

.data-table table {
  width: 100%;
  border-collapse: collapse;
}

.data-table table .index-col-head,
.data-table table .index-col {
  min-width: 24px;
  text-align: right;
  padding-right: 10px;
}

.data-table table .index-col {
  color: #B3B3B3;
}

.data-table table .fixed-width {
  width: 96px;
}

.data-table table .has-hint-icon > i.fa-solid {
  margin-left: 6px;
}

.data-table table thead {
  font-weight: bold;
}

.data-table table thead tr {
  background-color: #c0c0c0;
}

.data-table table thead tr, tbody tr {
  border-top: solid 1px #a4a4a4;
  border-bottom: solid 1px #a4a4a4;
}

.data-table table tbody tr:hover {
  background-color: #eeeeee;
}

.data-table table tbody tr.highlight {
  font-weight: bold;
}

.data-table table tbody tr.header-expanded {
  background-color: #f0f0f0;
  font-weight: bold;
}

.data-table table tbody tr.group-row {
  background-color: #f0f0f0;
}

.data-table table tbody tr.sub-row {
  background-color: #f0f0f0;
}

.data-table table tbody tr.solid-background {
  background-color: #dbdada;
  border-top: none;
  border-bottom: none;
}

.data-table table tbody tr.solid-background.bottom-bordered {
  border-bottom: solid 1px #a4a4a4;
}

.data-table table tbody tr.row-form {
  vertical-align: top;
}

.data-table table tbody tr.row-form td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.data-table table tbody tr.row-form td > input {
  margin-bottom: 4px;
}

.data-table table td, .data-table table th {
  height: 50px;
}

.data-table table th.right-aligned {
  text-align: right;
  padding-right: 12px;
}

.data-table table td.right-aligned {
  text-align: right;
  padding-right: 12px;
}

.data-table table tbody .currency-symbol {
  margin-right: 2px;
  color: #B3B3B3;
}

.data-table table tbody .na-value {
  font-style: italic;
  color: #B3B3B3;
}

.data-table table tbody .row-controls {
  max-width: 40px;
  text-align: center;
}

.data-table table tbody .row-controls button {
  min-width: 70px;
  min-height: 32px;
}

.data-table table tbody .row-controls .danger-button {
  color: #D11111;
}

.data-table table .footer-row .currency-symbol {
  margin-right: 2px;
  color: #707070;
}

.data-table .record-link {
}

.data-table .record-link:hover,
.data-table .record-link:active {
  color: #6088ED;
}

.data-table .expandable-action {
}

.data-table .expandable-action a {
  color: black;
  display: flex;
  height: 100%;
  justify-content: start;
  align-items: center;
}

.data-table .expandable-action a > span {
  padding-right: 12px;
}

.data-table .action-expanding a,
.data-table .action-expanding span {
  color: #c3bebe;
}

.data-table .expandable-action .fa-solid {
  font-size: 20px;
}

.data-table table .footer-row {
  background-color: #dddddd;
  font-weight: bold;
}

.data-table::-webkit-scrollbar {
  height: 4px;
}

.data-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.data-table::-webkit-scrollbar-thumb {
  background: #b4b3b3;
}

.data-table::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}

.table-empty-row {
  color: #c3bebe;
  display: block;
  text-align: center;
  vertical-align: middle;
  margin: 20px 0 20px 0;
}

.table-loading-indicator {
  color: #c3bebe;
  display: block;
  text-align: center;
  vertical-align: middle;
  margin: 8px 0 8px 0;
  font-size: 52px;
}
