.controls-grid {
  border-bottom: solid 1px #a4a4a4;
  margin: 12px -24px 30px;
}

.controls-grid .controls-group {
  background-color: #eeeeee;
  border-top: solid 1px #a4a4a4;
  min-height: 50px;
  font-weight: bold;
  padding: 12px 20px 12px 26px;
}

.controls-grid .controls-items {
  padding: 12px 20px 26px 26px;
  display: flex;
}

.controls-grid .controls-items button {
  background-color: white;
  border: none;
  border-radius: 4px;
  text-align: center;
  margin-right: 12px;
  width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.controls-grid .controls-items button > i {
  display: block;
  margin-top: 32px;
  font-size: 72px;
  font-weight: bold;
}

.controls-grid .controls-items button > span {
  margin-bottom: 16px;
}

.controls-grid .controls-items button:hover {
  background-color: #EEEEEE;
}

.controls-grid .controls-items button:active {
  background-color: #A6A6A6;
}
