.modal-controls-grid {
  border-bottom: solid 1px #a4a4a4;
}

.modal-controls-grid .modal-controls-group {
  background-color: #eeeeee;
  border-top: solid 1px #a4a4a4;
  min-height: 50px;
  font-weight: bold;
  padding: 12px 20px;
}

.modal-controls-grid .modal-controls-items {
  padding: 12px 20px 16px;
  display: flex;
}

.modal-controls-grid .modal-controls-items button {
  background-color: white;
  border: none;
  border-radius: 4px;
  text-align: center;
  margin-right: 12px;
  min-width: 220px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.modal-controls-grid .modal-controls-items button > i {
  display: block;
  margin-top: 32px;
  font-size: 92px;
  font-weight: bold;
}

.modal-controls-grid .modal-controls-items button > span {
  margin-bottom: 16px;
}

.modal-controls-grid .modal-controls-items button:hover {
  background-color: #EEEEEE;
}

.modal-controls-grid .modal-controls-items button:active {
  background-color: #A6A6A6;
}
