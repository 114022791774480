.login-info {
  color: white;
  width: 300px;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin-top: 6px;
  padding-right: 8px;
  padding-top: 14px;
  padding-bottom: 14px;
  align-items: center;
  justify-content: flex-end;
}

.login-info .user-info {
  font-size: 16px;
  font-weight: bold;
  padding-right: 12px;
  display: inline-block;
  border-top-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: white;
}

.login-info .user-info>.user-rank {
  font-size: 14px;
  font-weight: normal;
}

.login-info .logout {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

.login-info>.logout a {
  color: white;
  text-decoration: none;
  margin-left: 8px;
  padding: 14px;
  border-radius: 4px;
}

.login-info>.logout a:hover {
  text-decoration: none;
  background-color: #C9D3EB;
}

.login-info>.logout a:active {
  text-decoration: none;
  background-color: #7D95D1;
  color: #DECDA3;
}