.form-section .arrival-inspection-form {
  margin-top: 20px;
}

.form-section .service-header-section,
.form-section .generator-section ,
.form-section .test-details-section,
.form-section .test-results-section,
.form-section .additional-info-section {
  margin-top: 20px;
  grid-auto-flow: column;
}
