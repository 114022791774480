/* 1280x800 */
@media (max-width: 800px) {
  .popup-modal {
    top: 120px;
    left: 40px;
    right: 40px;
    /*bottom: 160px;*/
    min-height: 900px;
  }

  .popup-modal-small {
    left: 40px;
    right: 40px;
  }

  .popup-modal-tiny {
    left: 100px;
    right: 100px;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .popup-modal {
    top: 120px;
    left: 100px;
    right: 100px;
    bottom: 60px;
    /*min-height: 640px;*/
  }

  .popup-modal-small {
    left: 200px;
    right: 200px;
  }

  .popup-modal-tiny {
    left: 200px;
    right: 200px;
  }
}

@media (min-width: 1281px) {
  .popup-modal {
    top: 120px;
    left: 220px;
    right: 220px;
    bottom: 60px;
    /*min-height: 640px;*/
  }

  .popup-modal-small {
    left: 360px;
    right: 360px;
  }

  .popup-modal-tiny {
    left: 520px;
    right: 520px;
  }
}

.popup-modal {
  position: absolute;
  overflow-y: auto;
  background-color: white;
  border: solid 1px lightgray;
  border-radius: 4px;
  z-index: 3100;
}

.popup-modal .modal-data-table::-webkit-scrollbar {
  height: 4px;
}

.popup-modal .modal-data-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.popup-modal .modal-data-table::-webkit-scrollbar-thumb {
  background: #b4b3b3;
}

.popup-modal .modal-data-table::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}

.popup-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #C0C0C0DB;
  z-index: 3000;
}

.popup-modal-small {
  min-width: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.popup-modal-tiny {
  min-height: 340px;
  bottom: unset;
}

.modal-loading-indicator {
  color: #c3bebe;
  display: block;
  text-align: center;
  vertical-align: middle;
  margin: 8px 0 8px 0;
  font-size: 52px;
}

.popup-modal-expand {
  max-height: unset;
}

.modal-heading {
  margin: 20px 20px 16px;
}

.modal-floated-controls {
  display: inline-block;
  float: right;
  position: relative;
  top: -50px;
  right: 24px;
}

.modal-floated-controls button {
  min-width: 28px;
  min-height: 28px;
  color: gray;
}

.modal-floated-controls button .fa-solid {
  margin-right: 0;
}

.modal-contents {
  margin: 8px 20px 20px;
}

.modal-contents.no-margin {
  margin-left: 0;
  margin-right: 0;
}

.modal-contents .modal-warning-message {
  padding: 6px 12px 6px 0;
  color: #d18c0e;
}

.modal-contents .modal-warning-message > .fa-solid {
  margin-right: 8px;
}

.modal-data-table {
  margin-bottom: 30px;
  overflow-x: auto;
  overflow-y: scroll;
  width: 100%;
  max-height: 100%;
}

.modal-padded-top {
  margin-top: 24px;
}

.modal-data-table table {
  width: 100%;
  border-collapse: collapse;
}

.modal-data-table table .index-col-head, .index-col {
  min-width: 24px;
  text-align: right;
  padding-right: 10px;
}

.modal-data-table table .index-col {
  color: #B3B3B3;
}

.modal-data-table table .index-col-head.small-col {
  width: 64px;
}

.modal-data-table table .number-col {
  text-align: right;
}

.modal-data-table table .na-col {
  color: gray;
  font-style: italic;
}

.modal-data-table table .zero-col {
  color: #B3B3B3;
}

.right-padded {
  min-width: 6px;
}

.modal-data-table table .fixed-width {
  width: 64px;
}

.modal-data-table table thead {
  font-weight: bold;
}

.modal-data-table table thead .center-aligned {
  text-align: center;
}

.modal-data-table table thead tr {
  background-color: #c0c0c0;
}

.modal-data-table table thead tr, tbody tr, tfoot tr {
  border-top: solid 1px #a4a4a4;
  border-bottom: solid 1px #a4a4a4;
}

.modal-data-table table tbody tr:hover {
  background-color: #eeeeee;
}

.modal-data-table table tbody .center-aligned {
  text-align: center;
}

.modal-data-table table tbody .fa-solid {
  margin-right: 8px;
}

.modal-data-table table tfoot {
  background-color: #eee;
}

.modal-data-table table td, .modal-data-table table th {
  height: 50px;
}

.modal-data-table .record-link {
}

.modal-data-table .record-link:hover,
.modal-data-table .record-link:active {
  color: #6088ED;
}

.modal-data-table .table-empty-row {
  color: #c3bebe;
  display: block;
  text-align: center;
  vertical-align: middle;
  margin: 20px 0 20px 0;
}

.modal-form {
  margin: 12px 20px 8px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: "div div div div";
  grid-column-gap: 12px;
  grid-row-gap: 16px;
}

.modal-form .form-message {
  grid-column: 1/13;
}

.modal-form .form-label {
  grid-column: 1/5;
  padding-top: 6px;
}

.modal-form .form-input {
  grid-column: 5/13;
}

.modal-from .form-input label {
  margin-right: 12px;
  border: solid 1px lightgray;
  padding: 6px 8px;
  border-radius: 4px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.modal-from .form-input input[type="radio"],
.modal-from .form-input input[type="checkbox"],
.modal-from .form-input select {
}

.modal-from .form-input .option-field > input {
  margin-right: 8px;
}

.modal-from .form-field-message {
  margin-top: 8px;
}

.modal-from .form-side-label {
  grid-column: 9/13;
  padding: 6px 12px;
  color: #a6810d;
}

.modal-from .form-side-label i {
  margin-right: 8px;
  color: #544108;
}

.modal-from .has-autocompleted-value {
  background-color: #d9eaff;
}

.modal-form .readonly-field {
  padding: 6px 12px;
  font-weight: bold;
}

.modal-form .values-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: "div div";
  grid-column-gap: 0;
  grid-row-gap: 0;
}

.modal-form .values-grid .readonly-field {
  grid-column: 1/9;
  padding: 12px 12px;
}

.modal-form .values-grid .field-control {
  grid-column: 9/13;
  text-align: right;
  margin-top: 6px;
}

.modal-form .values-grid .field-control button {
  min-width: 70px;
  min-height: 32px;
}


.modal-form .values-grid .field-control .danger-button {
  color: #D11111;
}

.modal-controls {
  margin: 8px 20px 16px;
  padding-top: 6px;
}

.modal-controls button {
  margin-right: 10px;
}
