.footer-fullwidth {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-bottom: 24px;
}

.footer-container {
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  text-align: center;
  z-index: -10000;
}
