.quotation-requests-table {
}

.quotation-requests-table table .customer-name-col {
  min-width: 150px;
}

.quotation-requests-table table .business-type-col {
  min-width: 100px;
}

.quotation-requests-table table .contact-name-col {
  min-width: 150px;
}

.quotation-requests-table table .contact-no-col {
  min-width: 80px;
}

.quotation-requests-table table .date-col {
  min-width: 130px;
}

.quotation-requests-table table .actor-col {
  min-width: 120px;
}

.quotation-requests-table table .review-status-col {
  min-width: 80px;
}

.quotation-requests-table table .review-status-col {
  min-width: 80px;
}

.quotation-requests-container {
}
