.order-confirmation-table {
}

.order-confirmation-table table .customer-name-col {
  min-width: 150px;
}

.order-confirmation-table table .company-col {
  min-width: 120px;
}

.order-confirmation-table table .genset-model-col {
  min-width: 100px;
}

.order-confirmation-table table .genset-qty-col {
  min-width: 50px;
}

.order-confirmation-table table .genset-price-col {
  min-width: 100px;
}

.order-confirmation-table table .payment-term-col {
  min-width: 80px;
}

.order-confirmation-table table .status-col {
  min-width: 80px;
}

.order-confirmation-table table .date-col {
  min-width: 120px;
}

.order-confirmation-table table .actor-col {
  min-width: 120px;
}

.purchase-agreements-container {
}
