/* 1280x800 */
@media (max-width: 800px) {
  .side-nav {
    width: 256px;
    position: fixed;
    left: -256px;
  }

  .side-nav .modules {
  }

  #navbar {
    transition: left 300ms ease;
  }

  #navbar.show {
    left: 0;
  }

  .side-nav .module-section .module-header {
    padding-bottom: 8px;
  }

  .module-nav-item {
    display: block;
  }

  .module-nav-item-tablet {
    display: none;
  }

  .module-navbar a,
  .module-navbar a:hover,
  .module-navbar a:active {
    display: flex;
    padding: 14px 0 14px 14px;
    height: 52px;
  }

  .module-nav-item-selected a,
  .module-nav-item-selected a:hover,
  .module-nav-item-selected a:active {
    font-weight: bold;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .side-nav {
    width: 100px;
    position: fixed;
    left: 0;
  }

  .side-nav .module-section .module-header {
    font-size: 12px;
    padding-bottom: 0;
  }

  .module-nav-item {
    display: none;
  }

  .module-nav-item-tablet {
    display: block;
  }

  .module-navbar a,
  .module-navbar a:hover,
  .module-navbar a:active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    text-align: center;
  }

  .module-navbar a > i,
  .module-navbar .module-nav-item-selected a > i {
    font-size: 26px;
    margin-bottom: 6px;
  }

  .module-navbar a:hover {
  }

  .module-navbar a:active {
  }

  .nav-item-label {
    font-size: 14px;
  }

  .module-nav-item-selected a,
  .module-nav-item-selected a:hover,
  .module-nav-item-selected a:active {
    font-weight: normal;
  }
}

@media (min-width: 1281px) {
  .side-nav {
    width: 256px;
    position: fixed;
    left: 0;
  }

  .side-nav .modules {
    padding-top: 12px;
  }

  .side-nav .module-section .module-header {
    padding-bottom: 8px;
  }

  .module-nav-item {
    display: block;
  }

  .module-nav-item-tablet {
    display: none;
  }

  .module-navbar a,
  .module-navbar a:hover,
  .module-navbar a:active {
    display: flex;
    padding: 14px 0 14px 14px;
    height: 52px;
  }

  .module-nav-item-selected a,
  .module-nav-item-selected a:hover,
  .module-nav-item-selected a:active {
    font-weight: bold;
  }
}

.side-nav {
  background-color: #A3B4DE;
  box-shadow: 3px 0 8px gray;
  top: 80px;
  bottom: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 28px;
}

.side-nav .module-section {
  margin-top: 28px;
  padding-bottom: 8px;
  border-bottom-style: solid;
  border-bottom-color: #847D7D;
  border-bottom-width: 1px;
}

.side-nav .module-section .module-header {
  padding-left: 10px;
  padding-right: 10px;
  color: #847D7D;
}

.side-nav .module-section .module-navbar {
}

.module-navbar a,
.module-navbar a:hover,
.module-navbar a:active {
  text-decoration: none;
  color: black;
  border-right-width: 6px;
  border-right-style: solid;
  border-right-color: #A3B4DE;
}

.module-navbar a > i,
.module-navbar .module-nav-item-selected a > i {
  width: 24px;
}

.module-navbar a:hover {
  background-color: #C9D3EB;
  border-right-color: #C9D3EB;
}

.module-navbar a:active {
  color: #DECDA3;
  background-color: #7D95D1;
  border-right-color: #7D95D1;
}

.module-nav-item-selected {
}

.module-nav-item-selected a,
.module-nav-item-selected a:hover,
.module-nav-item-selected a:active {
  background-color: #7D95D1;
  border-right-color: #DECDA3;
  color: #DECDA3;
}
