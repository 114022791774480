.form-button-controls .prompt-message {
  grid-column: 1/13;
}

.form-button-controls .prompt-message > h1 {
  font-size: 18px;
}

.form-button-controls .prompt-message > h1 > i {
  margin-right: 8px;
}

.form-button-controls .prompt-message > p {
  margin-bottom: 8px;
}

.form-button-controls .prompt-message > button {
  margin-top: 6px;
}

.form-button-controls .prompt-message.prompt-info > button {
  background-color: #0aa3c1;
  color: #fff;
}

.form-button-controls .prompt-message.prompt-warning > button {
  background-color: #c99806;
  color: #fff;
}

.form-button-controls .prompt-message.prompt-danger > button {
  background-color: #cf3b48;
  color: #fff;
}