.service-search-results-table table .primary-col {
  min-width: 180px;
}

.service-search-results-table table .secondary-col {
  min-width: 180px;
}

.service-search-results-table table .tertiary-col {
  min-width: 120px;
}

.service-search-results-table table .expandable-action > a > i {
  margin-right: 8px;
}

.service-search-results-table .inline-field-label {
  font-weight: bold;
}

.service-search-results-table .inline-section-controls {
  padding-top: 10px;
  padding-bottom: 16px;
}

.service-search-results-table .inline-section-controls > button {
  margin-right: 16px;
}

.service-search-results-table .inline-field-errors {
  padding-bottom: 10px;
}
