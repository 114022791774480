.dashboard-widget {
}

.widget-floated-controls {
  display: inline-block;
  float: right;
  position: relative;
  top: -52px;
  right: 2px;
}

.widget-contents {
  margin: 8px 0 20px;
}

.widget-data-table {
  margin-bottom: 30px;
  max-height: 100%;
}

.dashboard-empty {
  color: #847D7D;
  height: 520px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-empty-icon {
  font-size: 12em;
  color: #e6e6e6;
}

.dashboard-empty-message {
  margin-top: 16px;
  color: #b6b3b3;
}
