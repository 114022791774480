.form-section .contact-log-entry-form {
  margin-top: 20px;
  grid-auto-flow: column;
}

.contact-log-entry-form .location-field {
}

.contact-log-entry-form .location-field .location-name {
  margin-bottom: 12px;
}

.contact-log-entry-form .location-field .location-address {
  margin-bottom: 12px;
}
