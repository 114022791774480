.service-profiles-list-container {
}

.data-table .customer-name-head {
  max-width: 200px;
}

.data-table .business-type-head {
  max-width: 120px;
}
