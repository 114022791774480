.empty-sales-targets-grid {
  color: #c3bebe;
  display: block;
  text-align: center;
  margin: 48px 0 64px 0;
}

.loading-sales-targets-grid {
  color: #c3bebe;
  display: block;
  text-align: center;
  margin: 48px 0 64px 0;
}

.sales-targets-grid {
  border-bottom: solid 1px #a4a4a4;
  margin: 12px -24px 30px;
}

.sales-targets-grid .sales-targets-heading {
  background-color: #eeeeee;
  border-top: solid 1px #a4a4a4;
  min-height: 50px;
  font-weight: bold;
  padding: 12px 20px 12px 26px;
}

.sales-targets-grid .sales-targets-group {
  padding-bottom: 12px;
}

/* 1280x800 */
@media (max-width: 800px) {
  .sales-targets-grid .sales-targets-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .sales-targets-grid .sales-targets-items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1281px) {
  .sales-targets-grid .sales-targets-items {
    grid-template-columns: repeat(4, 1fr);
  }
}

.sales-targets-grid .empty-sales-targets-items {
  color: #c3bebe;
  display: block;
  text-align: center;
  margin: 32px 0 8px 0;
}

.sales-targets-grid .sales-targets-items {
  padding: 6px 24px 6px 24px;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  width: 100%;
}

.sales-targets-grid .group-label {
  color: #847D7D;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 12px;
}

.sales-targets-grid .sales-targets-items button {
  background-color: white;
  border: solid 2px #A7A7A7;
  border-radius: 4px;
  text-align: left;
  /* margin-right: 12px; */
  min-height: 120px;
}

.sales-targets-grid .sales-targets-items button:hover {
  background-color: #EEEEEE;
}

.sales-targets-grid .sales-targets-items button:active {
  background-color: #A7A7A7;
}

.sales-targets-grid .sales-targets-items button.new-button {
  border: solid 2px #EEEEEE;
  color: gray;
  font-size: 48px;
  text-align: center;
}

.sales-targets-grid .sales-targets-items button.new-button:hover {
  border: solid 2px #EEEEEE;
}

.sales-targets-grid .sales-targets-items button.new-button:active {
  background-color: #A7A7A7;
  border: solid 2px #A7A7A7;
}

/* NOTE(yemon): Making this an independent class to just get things easy to manage. That's all. No other reason. */
.sales-target-button {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 4px 8px;
}

.sales-target-button .target-date-range {
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
}

.sales-target-button .target-progresses {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.sales-target-button .target-progresses > .target-progress {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
}

.sales-target-button .target-progresses > .target-progress > .target-progress-label {
  min-width: 50px;
}

.sales-target-button .target-progresses > .target-progress > .target-progress-bar {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: #b0b0b0;
  height: 10px;
}

.sales-target-button .target-progresses > .target-progress > .target-progress-bar > .target-progress-bar-foreground {
  border-radius: 4px;
  background-color: #0cf2ad;
  display: inline-block;
  height: 10px;
  /* width: 70%; */
  z-index: 10;
}
