.quotation-entry-form {
  margin-top: 20px;
}

.btn-long {
  min-width: 320px;
}

.customer-profile-status-label {
  margin-left: 12px;
  display: inline-block;
}

.customer-profile-status-label i.fa-solid {
  font-weight: bold;
  margin-right: 8px;
  color: #029f02;
}
