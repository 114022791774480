.inquiry-history-table {
}

.inquiry-history-table table .date-col {
  min-width: 130px;
}

.inquiry-history-table table .reporter-col {
  min-width: 120px;
}

.inquiry-history-table table .genset-model-col {
  min-width: 100px;
}

.inquiry-history-table table .contact-form-col {
  min-width: 80px;
}

.inquiry-history-table table .discussions-col {
  min-width: 220px;
}

.inquiry-history-table table .follow-up-col {
  min-width: 140px;
}

.inquiry-history-table table .reminder-date-col {
  min-width: 130px;
}
