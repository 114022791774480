.accordion-section {
  display: block;
}

.accordion-header {
  display: block;
  border-left: solid 4px #7D95D1;
  padding: 20px 36px 20px 16px;
  font-style: normal;
  color: black;
  background-color: #eee;
}

.accordion-header:active {
  color: black;
}

.accordion-header:hover {
  background-color: lightgray;
  color: black;
}

.accordion-header .indicator {
  float: right;
  font-size: 1.4em;
}

.accordion-header.collapsed {
  /*border-left: solid 4px white;*/
  border-left: solid 4px #7D95D1;
}

.accordion-content {
  padding: 8px 20px 24px 20px;

  display: block;
  visibility: visible;
  opacity: 1;
  /*NOTE(yemon): The transition does not really work, it seems... */
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.accordion-content.collapsed {
  display: none;
  opacity: 0;
  visibility: hidden;;
}
