.badge {
  font-size: 14px;
}

.badge-draft {
  color: #fff;
  background-color: #6c757d;
}

.badge-pending-review {
  color: #212529;
  background-color: #ffc107;
}

.badge-approved {
  color: #fff;
  background-color: #28a745;
}

.badge-rejected {
  color: #fff;
  background-color: #dc3545;
}

.badge-active {
  color: #fff;
  background-color: #17a2b8;
}

.badge-canceled {
  color: #fff;
  background-color: #343a40;
}
