/* 1280x800 */
@media (max-width: 800px) {
  .header .nav-expand-button {
    display: inline-block;
    border-right-style: solid;
    border-right-width: 4px;
    border-right-color: #A3B6E7;
    padding-right: 8px;
    margin-right: 8px;
  }

  .header .nav-expand-button > button {
    background-color: #5876c3;
    color: white;
    min-width: 60px;
    min-height: 60px;
    border-radius: 4px;
    border-style: none;
  }

  .header .nav-expand-button > button > i {
    margin-right: 0;
    font-size: 32px;
  }

  .header .nav-expand-button > button:active,
  .header .nav-expand-button > button:hover {
    background-color: #404e85;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .header .nav-expand-button {
    display: none;
  }
}

@media (min-width: 1281px) {
  .header .nav-expand-button {
    display: none;
  }
}

.header {
  background-color: #5876c3;
  font-family: 'Roboto', sans-serif;
  font-size: 42px;
  font-weight: normal;
  color: white;
  padding: 8px;
  box-shadow: 0 4px 4px gray;
  justify-content: flex-start;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  flex-wrap: wrap;
}

.header a {
  color: white;
  text-decoration: none;
}

.header .logo {
  border-top-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-right-style: none;
  /*border-right-style: solid;*/
  /*border-right-width: 4px;*/
  /*border-right-color: #A3B6E7;*/
}

.header .logo > img {
  vertical-align: middle;
}

.header .title {
}

.header .title .company-name {
  font-weight: bold;
  margin-left: 14px;
  margin-right: 10px;
}
