.progressbar-container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.progressbar-container > .progressbar {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
}

.progressbar-container > .progressbar > .progressbar-label {
  min-width: 50px;
}

.progressbar-container > .progressbar > .progressbar-bar {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: #b0b0b0;
  height: 10px;
}

.progressbar-container > .progressbar > .progressbar-bar > .progressbar-bar-foreground {
  border-radius: 4px;
  /* background-color: #0cf2ad; */
  display: inline-block;
  height: 10px;
  z-index: 10;
}
