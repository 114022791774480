/* 1280x800 */
@media (max-width: 800px) {
  .page-alert {
    left: 0;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .page-alert {
    left: 100px;
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media (min-width: 1281px) {
  .page-alert {
    left: 256px;
    margin-left: 32px;
    margin-right: 32px;
  }
}

.page-alert {
  right: 0;
  bottom: 0;
  margin-bottom: 24px;
  position: fixed;
  border-width: 0.1rem;
  border-left-width: 0.4rem;
  border-style: solid;
  border-color: transparent;
  border-radius: 0.25rem;
  box-shadow: 0 0 .2rem #b6b3b3;
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.15s linear;
}

.page-alert.show {
  opacity: 1;
}

.page-alert.info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  border-left-color: #9cc5cb;
}

.page-alert.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border-left-color: #a2cba9;
}

.page-alert.warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  border-left-color: #c7b681;
}

.page-alert.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-left-color: #bb868c;
}

.page-alert .page-alert-content {
  margin: 14px 18px;
}

.page-alert .btn-dismiss {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  border: 0;
  padding: 0.75rem 1.25rem;
  font-size: 1.3rem;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  min-width: 64px;
  opacity: .5;
}
