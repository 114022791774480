/* 1280x800 */
@media (max-width: 800px) {
  .pagination-settings button {
    min-width: 42px;
  }

  .pagination-settings button span {
    display: none;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
}

@media (min-width: 1281px) {
}

.table-pagination {
  text-align: right;
  padding-top: 24px;
  padding-bottom: 12px;
  padding-right: 24px;
  display: flex;
}

.table-pagination .pagination-settings {
  margin-left: 24px;
  margin-right: auto;
}

.table-pagination .pagination-summary {
  min-height: 46px;
  margin-right: 16px;
  padding-top: 12px;
}

.table-pagination .pagination-controls {
  display: inline-block;
  border: solid 1px #a4a4a4;
  border-radius: 6px;
}

.table-pagination .pagination-controls div {
  display: inline-block;
}

.table-pagination .pagination-controls div > button {
  min-width: 46px;
  min-height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.table-pagination .pagination-controls div > button {
  background-color: transparent;
  border-style: none;
}

.table-pagination .pagination-controls div > button:hover,
.table-pagination .pagination-controls div > button:active {
  color: #A6A6A6;
}

.table-pagination .pagination-controls .first-arrow,
.table-pagination .pagination-controls .prev-arrow,
.table-pagination .pagination-controls .next-arrow,
.table-pagination .pagination-controls .last-arrow
{
  min-width: 46px;
  min-height: 46px;
  padding-left:4px;
}

.table-pagination .pagination-controls .page-number {
  min-width: 46px;
  min-height: 46px;
  padding-left: 2px;
  padding-right: 2px;
}

.table-pagination .pagination-controls .page-number-dropdown {
  /* Original Bootstrap styles */
  margin: 0;
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: inherit;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none;
  /* Page dropdown control styles */
  width: 100px;
  height: 46px;
}

.table-pagination .pagination-controls .page-number-dropdown:focus {
  /* Original Bootstrap :focus styles. Disabled for the page dropdown control */
  /*border: 1px solid #86b7fe;*/
  /*outline: 0;*/
  /*box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);*/
}

.table-pagination .pagination-controls .first-arrow {
  border-right: solid 1px #a4a4a4;
}

.table-pagination .pagination-controls .prev-arrow {
}

.table-pagination .pagination-controls .page-number {
  border-right: solid 1px #a4a4a4;
  border-left: solid 1px #a4a4a4;
}

.table-pagination .pagination-controls .next-arrow {
}

.table-pagination .pagination-controls .last-arrow {
  border-left: solid 1px #a4a4a4;
}
