/* 1280x800 */
@media (max-width: 800px) {
  .widget-reminder-months {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .widget-reminder-months {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1281px) {
  .widget-reminder-months {
    grid-template-columns: repeat(3, 1fr);
  }
}

.widget-reminder-months {
  margin-top: 20px;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  width: 100%;
}

.widget-reminder {
  border: solid 2px #A7A7A7;
  border-radius: 4px;
  padding: 16px 24px;
  min-height: 360px;
}

.widget-reminder h2 {
  font-size: 22px;
  font-weight: bold;
  display: block;
  height: 32px;
}

.widget-reminder .widget-header {
}

.widget-reminder .widget-header i,
.widget-overdue .widget-header i {
  margin-right: 8px;
}

.widget-reminder .widget-badge {
  background-color: #A7A7A7;
  border-radius: 8px;
  display: inline-block;
  min-width: 32px;
  min-height: 32px;
  font-size: 14px;
  text-align: center;
  padding-top: 6px;
  position: relative;
  float: right;
  top: 0;
  color: white;
}

.widget-reminder .widget-body {
  max-height: 280px;
  overflow-y: auto;
}

.widget-reminder .widget-body .reminder-item {
}

.widget-reminder .widget-body .reminder-item button {
  background-color: white;
  border: none;
  border-radius: 4px;
  display: flex;
  width: 100%;
  height: 70px;
  text-align: left;
}

.widget-reminder .widget-body .reminder-item button:hover {
  background-color: #EEEEEE;
}

.widget-reminder .widget-body .reminder-item button:active {
  background-color: #A6A6A6;
}

.widget-reminder .widget-body .reminder-item .item-display {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 8px 6px;
}

.widget-reminder .widget-body .reminder-item .item-display .item-title {
  font-size: 16px;
}

.widget-reminder .widget-body .reminder-item .item-display .item-subtitle {
  font-size: 14px;
  margin-top: 10px;
}

.widget-reminder .widget-body .reminder-item .item-date-badge {
  font-size: 18px;
  color: #888888;
  display: inline-block;
  min-width: 28px;
  height: 100%;
  text-align: center;
  padding-top: 20px;
}

.widget-overdue {
}

.widget-overdue h2 {
  color: #D11111;
}

.widget-overdue .widget-badge {
  background-color: #D13F3F;
  color: white;
}

.widget-overdue .widget-body .reminder-item .item-date-badge {
  min-width: 50px;
}

.widget-loading-indicator {
  grid-column: 1/4;
  color: #c3bebe;
  display: block;
  text-align: center;
  vertical-align: middle;
  margin: 8px 0 8px 0;
  font-size: 52px;
}
