.tabs-panel {
  display: block;
  margin-bottom: 30px;
}

.tab-header {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 24px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 0;
}

.tab-header .tab-header-item {
  display: block;
  padding: 12px 22px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  background-color: white;
  border: solid 1px #a4a4a4;
  border-bottom: solid 1px white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: -0.22rem;
}

.tab-header .tab-header-item:hover {
  background-color: #eeeeee;
}

.tab-header .tab-header-item:active {
  background-color: #c0c0c0;
}

.tab-header .tab-header-item.active {
  font-weight: bold;
  background-color: white;
  border-top: solid 4px #7D95D1;
  border-bottom: solid 1px white;
}

.tab-content {
  border-top: solid 1px #a4a4a4;
  border-bottom: solid 1px #a4a4a4;
  margin-left: -24px;
  margin-right: -24px;
  padding: 20px 24px;
}

.tab-content .tab-content-pane {
  display: none;
}

.tab-content .tab-content-pane.active {
  display: block;
}
