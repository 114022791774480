/* 1280x800 */
@media (max-width: 800px) {
  .listing-controls {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .listing-controls button {
    margin-right: 8px;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .listing-controls {
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .listing-controls button {
    margin-right: 8px;
  }
}

@media (min-width: 1281px) {
  .listing-controls {
    margin-top: 16px;
    margin-bottom: 30px;
  }

  .listing-controls button {
    margin-right: 16px;
  }
}

.listing-controls {
  display: flex;
}

.listing-controls button {
}

.listing-controls .left {
  margin-right: auto;
}

.listing-controls .right {
  margin-left: 0;
  display: inline-block;
  right: 0;
}
