.inquiry-form {
  margin-top: 20px;
}

.followup-form {
  margin-top: 20px;
}

.followup-form {
}

.inquiry-history-generators {
}

.inquiry-history-generators .generator-item {
  display: grid;
  margin-bottom: 8px;
}

.inquiry-history-generators .generator-item .item-name {
  grid-column: 1/12;
}

.inquiry-history-generators .generator-item .danger-button {
  grid-column: 12/13;
  min-width: 40px;
  color: #D11111;
}

.genset-model-wrapper {
  display: flex;
}

.genset-model-wrapper > .genset-add {
  min-width: 80px;
}
