.service-locations-empty {
  display: block;
  padding: 20px 36px 20px 20px;
  font-style: italic;
  color: gray;
  background-color: #eee;
}

.service-location-controls {
  margin-bottom: 8px;
}

.service-location-controls button {
  margin-right: 10px;
}

.service-generator-item {
  margin-bottom: 8px;
}

.service-generator-item > label {
  margin-right: 12px;
  border: solid 1px lightgray;
  padding: 6px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.service-generator-item input {
  margin-right: 8px;
}

.service-generator-item-empty {
  margin-top: 8px;
  margin-bottom: 8px;
  color: gray;
  font-style: italic;
}
