/* 1280x800 */
@media (max-width: 800px) {
  .entry-form .form-label {
    grid-column: 1/5;
  }

  .entry-form .form-input {
    grid-column: 5/13;
  }

  .entry-form .form-input-inline {
    grid-column: 1/13;
  }

  .entry-form .form-label-r {
    grid-column: 1/5;
  }

  .entry-form .form-input-r {
    grid-column: 5/13;
  }

  .entry-form .form-input-inline-r {
    grid-column: 1/13;
  }

  .entry-form .form-side-label {
    grid-column: 5/13;
    padding: 0 12px 6px;
  }

  .entry-form .form-label-wide {
    grid-column: 1/5;
  }

  .entry-form .form-input-wide {
    grid-column: 5/13;
  }

  .entry-form .form-side-label-wide {
    grid-column: 5/13;
    padding: 0 12px 6px;
  }

  .entry-form .show-mobile {
    display: inherit;
  }

  .entry-form .hide-mobile {
    display: none;
  }

  .form-button-controls .left-side {
    grid-column: 1/13;
  }

  .form-button-controls .right-side {
    grid-column: 1/13;
  }

  .form-section-controls {
  }

  .form-section-controls button {
    margin-right: 8px;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .entry-form .form-label {
    grid-column: 1/4;
  }

  .entry-form .form-input {
    grid-column: 4/7;
  }

  .entry-form .form-input-inline {
    grid-column: 1/7;
  }

  .entry-form .form-label-r {
    grid-column: 7/10;
  }

  .entry-form .form-input-r {
    grid-column: 10/13;
  }

  .entry-form .form-input-inline-r {
    grid-column: 7/13;
  }

  .entry-form .form-side-label {
    grid-column: 3/7;
    padding: 0 12px 6px;
  }

  .entry-form .form-label-wide {
    grid-column: 1/5;
  }

  .entry-form .form-input-wide {
    grid-column: 5/13;
  }

  .entry-form .form-side-label-wide {
    grid-column: 5/13;
    padding: 0 12px 6px;
  }

  .entry-form .show-mobile {
    display: none;
  }

  .entry-form .hide-mobile {
    display: inherit;
  }

  .form-button-controls .left-side {
    grid-column: 1/13;
  }

  .form-button-controls .right-side {
    grid-column: 1/13;
  }

  .form-section-controls {
  }

  .form-section-controls button {
    margin-right: 8px;
  }
}

@media (min-width: 1281px) {
  .entry-form .form-label {
    grid-column: 1/4;
  }

  .entry-form .form-input {
    grid-column: 4/7;
  }

  .entry-form .form-input-inline {
    grid-column: 1/7;
  }

  .entry-form .form-label-r {
    grid-column: 7/10;
  }

  .entry-form .form-input-r {
    grid-column: 10/13;
  }

  .entry-form .form-input-inline-r {
    grid-column: 7/13;
  }

  .entry-form .form-side-label {
    grid-column: 7/13;
    padding: 6px 12px;
  }

  .entry-form .form-label-wide {
    grid-column: 1/5;
  }

  .entry-form .form-input-wide {
    grid-column: 5/11;
  }

  .entry-form .form-side-label-wide {
    grid-column: 5/13;
    padding: 0 12px 6px;
  }

  .entry-form .show-mobile {
    display: none;
  }

  .entry-form .hide-mobile {
    display: inherit;
  }

  .form-button-controls .left-side {
    grid-column: 1/6;
  }

  .form-button-controls .left-side.wide-col {
    grid-column: 1/8;
  }

  .form-button-controls .right-side {
    grid-column: 7/13;
  }

  .form-button-controls .right-side.narrow-col {
    grid-column: 8/13;
  }

  .form-section-controls {
  }

  .form-section-controls button {
    margin-right: 16px;
  }
}

.entry-form {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /*grid-template-areas: "div div div div";*/
  grid-column-gap: 16px;
  grid-row-gap: 20px;
}

.entry-form-inline {
}

.entry-form .form-message {
  grid-column: 1/13;
}

.entry-form .form-label,
.entry-form .form-label-r,
.entry-form .form-label-wide {
  /*grid-area: "div";*/
  padding-top: 6px;
}

.entry-form .form-input,
.entry-form .form-input-r,
.entry-form .form-input-inline,
.entry-form .form-input-inline-r {
  /*grid-area: "div";*/
}

.entry-form .form-input label,
.entry-form .form-input-r label,
.entry-form .form-input-wide label {
  margin-right: 12px;
  border: solid 1px lightgray;
  padding: 6px 8px;
  border-radius: 4px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.entry-form .form-input input[type="radio"],
.entry-form .form-input-r input[type="radio"],
.entry-form .form-input input[type="checkbox"],
.entry-form .form-input-r input[type="checkbox"],
.entry-form .form-input select,
.entry-form .form-input-r select {
}

.entry-form .form-input .option-field > input,
.entry-form .form-input-r .option-field > input,
.entry-form .form-input-wide .option-field > input {
  margin-right: 8px;
}

.entry-form .form-input .checkbox-field > input,
.entry-form .form-input-r .checkbox-field > input,
.entry-form .form-input-wide .checkbox-field > input {
  margin-right: 8px;
}

.entry-form .form-input-wide.has-block-options > label,
.entry-form .form-input.has-block-options > label {
  display: block;
  margin-bottom: 8px;
  margin-right: 0;
}

.entry-form .form-field-message {
  margin-top: 8px;
}

.entry-form .right-aligned {
  text-align: right;
  padding: 0 0.75rem;
}

.entry-form .form-side-label {
  color: #a6810d;
}

.entry-form .form-side-label i {
  margin-right: 8px;
  color: #544108;
}

.entry-form .has-autocompleted-value {
  background-color: #d9eaff;
}

.entry-form .inline-field-group {
  display: grid;
  grid-template-columns: auto min-content auto;
  grid-auto-flow: column;
}

.entry-form .inline-field-group > .inline-label {
  margin-left: 8px;
  margin-right: 8px;
}

.entry-form .select-field {
  margin: 0;
  cursor: pointer;

  /* Extract from Bootstrap's own `form-select` class definition */
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none;
}

.entry-form .select-field:focus {
  /* Extract from Bootstrap's own `form-select:focus` class definition */
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}

.entry-form .readonly-field {
  font-weight: bold;
  padding: 6px 12px 0;
}

.entry-form .instruction-message {
  padding: 6px 12px;
  font-style: italic;
}

.entry-form .warning-message {
  padding: 6px 12px;
  color: #d18c0e;
}

.entry-form .warning-message > .fa-solid {
  margin-right: 8px;
}

/*@media only screen and (max-width: 1536px) {*/
/*  .entry-form {*/
/*    display: grid;*/
/*  }*/
/*}*/

.form-button-controls {
  padding-top: 16px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 20px;
}

.form-button-controls.no-margin-bottom {
  margin-bottom: 0;
}

.form-button-controls button {
  margin-right: 16px;
}

.form-button-controls button.right-margin {
  margin-right: 24px;
}

.form-button-controls .left-side {
}

.form-button-controls .left-side.right-aligned {
  text-align: right;
}

.form-button-controls .right-side {
}

.form-button-controls .right-side.right-aligned {
  text-align: right;
}

.form-button-controls .left-margin {
  margin-left: 12px;
}

.form-button-controls .full-width {
  grid-column: 1/13;
}

.form-section-controls {
  margin-top: 16px;
  margin-bottom: 16px;
}

.form-section-controls button {
}

.form-section-controls button.right-margin {
  margin-right: 24px;
}

.form-contents {
  margin-top: 8px;
  margin-bottom: 20px;
}
