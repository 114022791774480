.form-section .sales-target-entry-form {
  margin-top: 20px;
  grid-auto-flow: column;
}

.sales-target-widget {
  /* background-color: white;
  border: solid 2px #A7A7A7;
  border-radius: 4px;
  text-align: left; */
  /* margin-right: 12px; */
  /* min-height: 120px;
  width: 100%; */

  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  /* padding: 4px 8px; */
}

.sales-target-widget .target-date-range {
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
}
